body, html {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
}

body {
    margin: 0 !important;
    font-family: 'Lexend', sans-serif !important;
    /*font-family: 'Roboto', 'sans-serif' !important;*/
    font-style: normal;
    background: #fcfafa !important;
}

.MuiTypography-body1, .MuiTableCell-root {
    font-family: 'Lexend', sans-serif !important;
}

.MuiAppBar-colorPrimary {
    background-color: #1B2132 !important;
}

.MuiButton-textPrimary {
    color: #1B2132 !important;
}

/* @font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(./fonts/glyphicons-halflings-regular.ttf) format('truetype');
} */

/* @font-face {
    font-family: 'roboto-condensed'; */
    /*src: local('Lato'), url(./fonts/RobotoCondensed-Regular.ttf) format('opentype');*/
    /* src: local('Lato'), url('./fonts/RobotoCondensed-Regular.ttf') format('opentype');
} */
