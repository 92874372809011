@import "../../assets/variables";

.table-title-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  //padding: 14px 10px 2px 10px;

}

.table-title {
  color: $primaryGreen;
  font-size: 30px !important;
  text-transform: capitalize;
  margin: 10px 0 !important;
}

.add-icon {
  //color: $primaryBlue;
  //border: 1px solid $primaryBlue;
  //border-radius: 30px;
  //padding: 6px 12px;
}
.img-table-style{
    width: 74px;
    height: 60px;
    border-radius: 10px;
}
.restaurant-logo-style{
  width:80px;
  height:70px;
  border-radius: 15px;
}


.bg-red {
  background: red;
  //color:red;
}
.color-red {
  color: red;
}

.bg-green {
  background: #098b51;
}
.color-yellow {
  color: yellow;
}
.color-green {
  color: #098b51;
}
.color-orange {
  color: orange;
}
.color-blue {
  color: blue;
}
.color-black {
  color: #000;
}
.color-pink {
  color: pink;
}
.bg-purple {
  background: mediumpurple;
}
.color-purple {
  color: mediumpurple;
}

.bg-lightgray {
  background:  #e0031f;
}
.color-lightgrey {
  color: rgba(145, 18, 18, 0.94);
}

.bg-lightpink {
  background:lightpink;
}
.color-lightpink {
  color: #e0031f;
}

.bg-lightskyblue {
  background:lightskyblue;
}
.color-lightskyblue {
  color: lightskyblue;
}

.bg-orange {
  background: orange;
}
.color-orange {
  color: orange;
}

.bg-light {
  background:rgba(106, 236, 177, 0.43);
  //color: $primaryGreen;
}

.previ-btn-research {
  background: #e5e8ef !important;
  border: 1px solid #dfe2eb !important;
  border-radius: 10px !important;
  color: #212121 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  // text-transform: uppercase !important;
}
.regulation-list-outer {
  margin-right: 20px;
}

.regulation-list-ml {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.table-more-option-btn-container {
  //display: flex!important;
  //justify-content: center;
  position: relative;

  .button-container {
    position: absolute;
    padding: 10px 0;
    top: 50px;
    right: 67%;
    background: #fcfafa;
    border: 1px solid #fcfafa;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 15px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    z-index: 50;

    .edit, .contract, .services {
      padding: 5px 20px 5px 10px;
      color: #4B686E;

      &:hover {
        background: #e3ebf6;
      }
    }
  }
}

.tender-title{
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-transform: capitalize;
  color: #212121;
  margin-bottom: -16px;
}

.tender-second-row-text{
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #696969;
}

.border-radius td {
  border-top: 1px solid #ECEDF2;
  border-bottom: 1px solid #ECEDF2;
  width: 100%;
}
.border-radius td:first-child {
  border-radius: 10px 0px 0px 10px;
  border-left: 1px solid #ECEDF2;
  width: 0% !important;
}
.border-radius td:last-child {
  border-radius: 0px 10px 10px 0px;
  border-right: 1px solid #ECEDF2;
}

.row-spacer-tr td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.row-spacer-tr td:first-child {
  border-radius: 10px 0px 0px 10px;
  border-left: 1px solid transparent;
}
.row-spacer-tr td:last-child {
  border-radius: 0px 10px 10px 0px;
  border-right: 1px solid transparent;
}

.table-border{
  border: 1px solid #E2E8F0;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px;
  background-color: #FFFFFF !important;
}

.table-heading{
  margin-top: 30px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #676767 !important;
}

.table-sub-heading{
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #676767 !important;
}

.columns-heading{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 12px !important;
  color: #676767 !important;
  margin-bottom: 12px !important;
}

.grph-box-outer {
  border-radius: 2px;
  background: #FFF;
  margin-bottom: 30px;
}

.grph-heading {
  font-size: 21.313px !important;
  font-weight: 600 !important;
  padding: 30px 30px 0px;
  
  span {
    color: #464A53 !important;
    font-size: 19.376px !important;
    font-weight: 400 !important;
  }
}
.bg-dash-text {
  background-color: rgba(14, 151, 255, 0.1);
  margin-bottom: 30px;
}
.dash-text {
  color: #0E97FF !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  letter-spacing: 0.22px !important;
  padding: 12px 35px !important;
}
