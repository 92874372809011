
.help-modal-container {
  z-index: 500;
  position: fixed;
  right: 35px;
  top: 130px;
  //bottom: 0;
  width: 600px;
  min-height: 20vh;
  max-height: 80vh;
  overflow: auto;
  background-color: white;
  padding: 10px 10px 10px 20px;
  border-radius: 15px !important;
}

.document-modal-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 700px;
  overflow-y: auto;
  background-color: #FFFFFF;
  // border: 2px solid #000;
  border-radius: 15px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, .10);
  padding: 60px;
  box-sizing: border-box;
}
.document-modal-paper-inner{
  padding: 10px;
  border: 1px solid #E2E2E2;
  border-radius: 15px;
}
.pdf-page-numbers{
  display: flex;
  justify-content: center;
}
.pdf-buttons{
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.page-turn-button{
  color: #fff;
  border: 1px solid #0E97FF;
  background-color: #0E97FF;
  border-radius: 50px;
  min-height: 0px;
  padding: 5px 20px;
  margin: 0px auto 0px auto;
  line-height: 1.25;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  cursor: pointer;
}

.page-turn-button+button{
  margin-left: 10px;
}

.page-turn-button+a{
  margin-left: 10px;
  text-decoration: none;
}

.page-turn-button:disabled{
  opacity: 0.5;
}

@media(max-width:767px) {
  .document-modal-paper {
      width: 300px;
      padding: 34px 25px;
      height: 500px;
  }

}
