//
//.loading-container {
//  width: 100%;
//  //height: 50vh;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  //position: absolute;
//  //top:40%;
//  //left: 45%;
//}

.MuiCircularProgress-colorPrimary{
  color: #1B2132!important;
}
